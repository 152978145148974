import React from "react"
import styled from 'styled-components'
import { Link } from '@components/atoms'
import { SimplePostProps } from '../../types/GridItemProps'
import { palette } from '@theme'
import { useDisplayMode, DisplayMode } from '@utils'


interface LinkMaskProps {
  post?: SimplePostProps
  link?: string
}


const LinkMask = ({ post, link }: LinkMaskProps) => {
  const { displayMode } = useDisplayMode()
  if(link) {
    return <AnchorWrapper href={link} />
  }
  if(post) {
    return <LinkWrapper to={`/${post.slug.current}`} $displayMode={displayMode} />
  }
  return <></>
}


const LinkMaskStyle = `
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  & ~ div .linkHighlight {
    transition: color 0.25s ease-in-out;    
  }
  &:hover ~ div .linkHighlight {
    color: ${palette.lime};
  }
`

const AnchorWrapper = styled.a`
  ${LinkMaskStyle}
`


const LinkWrapper = styled(props => <Link {...props} />)<{ $displayMode: DisplayMode }>`
  ${LinkMaskStyle}
  &:hover ~ div .linkHighlight {
    color: ${props => props.$displayMode === 'dark' ? palette.lime : palette.funGreen};
  }
`


export default LinkMask