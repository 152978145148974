exports.components = {
  "component---src-components-templates-project-tsx": () => import("./../../../src/components/templates/project.tsx" /* webpackChunkName: "component---src-components-templates-project-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lately-tsx": () => import("./../../../src/pages/lately.tsx" /* webpackChunkName: "component---src-pages-lately-tsx" */),
  "component---src-pages-studio-tsx": () => import("./../../../src/pages/studio.tsx" /* webpackChunkName: "component---src-pages-studio-tsx" */),
  "component---src-pages-tag-tsx": () => import("./../../../src/pages/tag.tsx" /* webpackChunkName: "component---src-pages-tag-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

