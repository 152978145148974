import React from "react"
import styled from 'styled-components'
import { Link } from '@components/atoms'
import { palette } from '@theme'
import { useDisplayMode, DisplayMode } from '@utils'


const ArrowLink = ({ to, children }: ArrowLinkProps): React.ReactElement => {
  const { displayMode } = useDisplayMode()
  return (
    <Wrapper {...{ displayMode }}>
      <Link {...{to}} >
        {children}
      </Link>
    </Wrapper>
  )
}

type ArrowLinkProps = {
  to: string,
  children: React.ReactNode,
}

const Wrapper = styled.div<{ displayMode: DisplayMode }>`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5em;

  padding-right: 45px;
  @media only screen and (min-width: 744px) {
    padding-right: 90px;
  }
 
  text-transform: uppercase;
  font-family: 'AkkuratMono', monospace;
  font-size: 0.9rem;
  line-height: 1em;
  @media only screen and (min-width: 744px) {
    font-size: 1.1rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 1.25rem;
  }
  a {
    color: ${props => props.displayMode === 'dark' ? palette.lime : palette.funGreen};
  }
  letter-spacing: 1.5px;
  
  &:after {
    content: ' ';
    position: absolute;
    top: 0px;
    right: 0;
    transition: right 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 36px;
    height: 9px;
    mask-image: url('/images/arrowLink.svg');
    mask-size: contain;
    background-color: ${props => props.displayMode === 'dark' ? palette.lime : palette.funGreen};
    @media only screen and (min-width: 744px) {
      top: -3px;
      width: 71px;
      height: 18px;
    }
  }
  
  &:hover {
    &:after {
      right: -10px;
    }
  }
`

export default ArrowLink