import { useInViewport } from "react-in-viewport"
import { useState, type RefObject } from "react"

export const useLazyLoad = (ref: RefObject<HTMLElement>) => {
  const [loaded, setLoaded] = useState(false)

  const { inViewport } = useInViewport(ref, {
    threshold: 0.15,
  })

  if (inViewport && !loaded) {
    setLoaded(true)
  }
  return { loaded, inViewport }
}
