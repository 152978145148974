export const palette = {
  lime: "rgb(173, 255, 0)",
  funGreen: "rgb(0, 104, 38)",
  white: "rgb(255, 255, 255)",
  mineShaft: "rgb(36, 36, 36)",
  silver: "rgb(159, 159, 159)",
  dove: "rgb(106, 106, 106)",
  black: "rgb(0, 0, 0)",
  racingGreen: "rgb(12, 37, 27)",
}

export const effect = {
  magnify: `
    @media only screen and (min-width: 920px) {
      transition: transform 0.25s ease-in-out;
      transform-origin: center center;
      transform: scale(1.001);
      &:hover {
        transform: scale(1.025);
      }  
    }
  `,
}
