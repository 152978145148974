import React from "react"
import styled from 'styled-components'
import { useAnimationFrame, useWindowSize, useDisplayMode } from '@utils'


const Laserz = () => {  
  const windowSize = useWindowSize()  
  const { partyMode } = useDisplayMode()
  if(!partyMode) return <></>
  return (
    <Wrapper>
      <svg height="100%" width="100%">
        <Ray {...{ windowSize }} />
        <Ray {...{ windowSize }} />
        <Ray {...{ windowSize }} />
        <Ray {...{ windowSize }} />
        <Ray {...{ windowSize }} />
        <Ray {...{ windowSize }} />
        <Ray {...{ windowSize }} />
        <Ray {...{ windowSize }} />
        <Ray {...{ windowSize }} />
      </svg>
    </Wrapper>
  )
}


const Ray = ({ windowSize }: { windowSize: { width: number, height: number }} ) => {
  const [points, setPoints] = React.useState({x: windowSize.width / 2, y: 0})
  const [origin, setOrigin] = React.useState<string>('')
  const angle = React.useRef<number>(Math.random() * 360)
  const speed = React.useRef<number>((Math.random() - 0.5) * 3)
  const radius = windowSize.width
  const flickerSpeed = Math.random() + 0.125
  React.useEffect(() => {
    if( typeof window === 'undefined' ) return
    const logo_coordinates = document.getElementById('logo_marker')?.getBoundingClientRect() || { y: 55 }
    const x = (window.innerWidth * .25)
    const y = logo_coordinates.y + window.scrollY - 25
    setOrigin(`M${x}, ${y}`)
  })
  
  useAnimationFrame(e => {
    const newAngle = angle.current + (e.delta * speed.current)
    setPoints({
      x: radius * Math.cos(newAngle) + windowSize.width/2,
      y: radius * Math.sin(newAngle) + windowSize.width / 2,
    })
    angle.current = (newAngle >= 360) ? 0 : newAngle
    
    const diceRoll = Math.random()
    if( diceRoll > 0.97 ) {
      speed.current = (Math.random() - 0.5) * 2
    }
  }, [])

  return (
    <path d={`${origin} ${points.x},${points.y}`}
     style={{
       strokeWidth: 1, 
       fill: 'transparent',
       animation: `flicker ${flickerSpeed}s linear infinite`,
     }}
    />
  )
}


const Wrapper = styled.div`
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`


export default Laserz
