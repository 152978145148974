import React from "react"
import styled from 'styled-components'
import { Link, Margin, Grid } from '@components/atoms'
import { palette } from '@theme'
import { useDisplayMode, DisplayMode } from '@utils'


const ReturnLink = ({ to, children }: ReturnLinkProps): React.ReactElement => {
  const { displayMode } = useDisplayMode()
  return (
    <Wrapper {...{ displayMode }}>
      <Margin>
        <StyledGrid>
          <Link {...{to}} >
            <Content {...{ displayMode }} >
              {children}
            </Content>
          </Link>
        </StyledGrid>
      </Margin>
    </Wrapper>
  )
}

type ReturnLinkProps = {
  to: string,
  children: React.ReactNode,
}



const StyledGrid = styled(props => <Grid {...props} />)`
  padding-top: 0;
`


const Content = styled.div<{
  displayMode: DisplayMode
}>`
  position: relative;
  grid-column-start: 2;
  grid-column-end: span 5;
  @media only screen and (min-width: 744px) {
    grid-column-start: 5;
    grid-column-end: span 8;
  }
  transition: color 0.15s ease-in-out;

  font-family: 'HCo Sentinel SSm', serif;
  font-weight: 300;
  font-size: 3rem;
  letter-spacing: 0;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 6.6rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 7.2rem;
  }
  
  &:before {
    content: ' ';
    position: absolute;
    top: calc(50% - 10px);
    left: -65px;
    width: 50px;
    height: 20px;
    
    @media only screen and (min-width: 744px) {
      left: -100px;
      width: 75px;
      
    }

    mask-image: url('/images/arrowLink.svg');
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
    background-color: ${props => props.displayMode === 'dark' ? palette.lime : palette.funGreen};
    transform: rotateZ(180deg);
    transition: transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`


const Wrapper = styled.div<{ displayMode: DisplayMode }>`
  position: relative;
  display: contents;
  a {
    display: contents;
    color: ${props => props.displayMode === 'dark' ? palette.white : palette.black} !important;
  }
  
  &:hover {
    ${Content} {
      color: ${palette.lime} !important;
      &:before {
        transform: rotateZ(180deg) translateX(15px);        
      }
    }
  }
`



export default ReturnLink