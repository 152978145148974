// usage:   {process.env.GATSBY_SANITY_WATCH_MODE && (<GridHelper />)}

import React from 'react'
import styled from 'styled-components'
import { Margin, Grid, InputSwitch } from '@components/atoms'
import { range } from 'lodash'
import { useGridHelperDispatch, useGridHelper } from './'


const GridHelperSwitch = (props: any) => {
  const dispatch = useGridHelperDispatch()
  const { gridHelperEnabled } = useGridHelper()
  if(process.env.NODE_ENV !== 'development') return <></>
  return (
    <>
      <InputSwitch {...props}
        name="gridHelperSwitch" 
        checked={gridHelperEnabled} 
        onChange={() => dispatch({type: 'toggle'})}
      >Grid Helper</InputSwitch>
      <GridHelperMargin className="gridHelper" {...{ gridHelperEnabled }} >
        <StyledGrid>
          {range(0, 12).map((_, i) => (
            <GridColumn key={`view_column_${i}`} hideMobile={(i > 5)} >{i + 1}</GridColumn>
          ))}
        </StyledGrid>
      </GridHelperMargin>
    </>
  )
}


const GridHelperMargin = styled(props => <Margin {...props} />)<{ gridHelperEnabled: boolean }>`
  display: ${props => props.gridHelperEnabled ? 'block' : 'none'};
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  outline: 5px solid red;
  box-sizing: border-box;
  pointer-events: none;
`


const StyledGrid = styled(props => <Grid {...props} />)`
  margin: auto;
  padding: 0 !important;
  grid-row-gap: 0 !important;
`


const GridColumn = styled.div<{ hideMobile: boolean }>`
  @media only screen and (max-width: 743px) {
    display: ${props => props.hideMobile ? 'none' : 'flex'};
  }
  grid-column: span 1;
  display: flex;
  justify-content: flex-start; 
  flex-direction: column-reverse;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.05);
  text-align: center;
  font-size: 9px;
  color: white;
`


export default GridHelperSwitch