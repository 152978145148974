import React from "react"
import styled from 'styled-components'


interface InputSwitchProps {
  children: React.ReactNode
  name: string
  onChange: () => void
  required?: boolean
  checked?: boolean
  labelColor?: string
  foregroundColor?: string
  backgroundColor?: string
  className?: string
}


const InputSwitch = ({
  children,
  name,
  onChange,
  required,
  checked,
  labelColor,
  foregroundColor,
  backgroundColor,
  className,
}: InputSwitchProps): React.ReactElement => (
  <Wrapper {...{ className }}>
  	<Label color={labelColor} >{children}</Label>
  	<Input
  	  type="checkbox"
  	  {... { name, onChange, checked }}
  	  required={!!required}
  	/>
  	<Switch {...{ checked, backgroundColor }} color={foregroundColor} />
  </Wrapper>
)


// Styling

const Wrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0.5em;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

const Switch = styled.span<{ checked?: boolean, color?: string, backgroundColor?: string,  }>`
  position: relative;
  background-color: ${props => props.color ? props.backgroundColor : 'black'};
  width: 55px;
  height: 14px;
  border: 1px solid ${props => props.color ? props.backgroundColor : 'black'};
  border-radius: 12px;
  appearance: none;
  transition:
	background-color 0.4s ease-in-out,
	border-color 0.4s ease-in-out;
  will-change: background-color, border-color;

  &:after {
  	content: '';
  	position: absolute;
  	left: 0;
  	top: 1px;
  	display: block;
  	background-color: ${props => props.color ? props.color : 'white'};
  	width: 10px;
  	height: 10px;
  	border-radius: 100%;
  	transform: translateX(${props => props.checked ? 42 : 1}px);
  	transition: transform 0.2s ease-in;
  	will-change: background-color, transform;
  }
`

const Label = styled.span<{ color?: string }>`
  color: ${props => props.color ? props.color : 'inherit'};
  max-width: 280px;
  min-width: 55px;
  letter-spacing: 0;  
  font-family: 'AkkuratMono', monospace;
  font-size: 0.9rem;
  margin-right: 10px;
  @media only screen and (min-width: 744px) {
    font-size: 1.4rem;
    margin-right: 15px;
  }
`


export default InputSwitch
