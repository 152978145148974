import React from "react"
import styled from 'styled-components'
import { range } from 'lodash'
import { useDisplayMode, useWindowSize } from '@utils'


const Confetti = ({ logoDown, scale }: { logoDown: boolean, scale: number }) => {
  const { partyMode } = useDisplayMode()
  const windowSize = useWindowSize();
  if(!partyMode) return <></>
  
  const colors = [
  	'rgb(173, 255, 0)',
		'rgb(0,  104, 38)',
		'rgb(255, 0, 173)',
		'rgb(255, 173, 0)',
		'rgb(173, 0, 255)',
  ]
  const fettiCount = (windowSize.width < 500) ? 30 : 60
  return (
    <Wrapper {...{ logoDown, scale }}>
      {range(0, fettiCount).map(i => (
        <Fetti 
          key={`fetti_${i}`}
          {...{ logoDown }}
          left={Math.floor(Math.random() * 100)} 
          animationDelay={Math.random() * 3}
          backgroundColor={colors[Object.keys(colors)[ Object.keys(colors).length * Math.random() << 0]]}
        />
      ))}
    </Wrapper>
  )
}



const Wrapper = styled.div<{ scale: number, logoDown: boolean }>`
  position: fixed;
  z-index: 99999;
  width: ${props => props.logoDown ? 100 : props.scale * 100}%;
  @keyframes confetti {
     0% {
        opacity:0;
        transform:translateY(0%) rotate(0deg) rotateY(0deg);
     }
     10% {
        opacity:1;
     }
     35% {
        transform:translateY(-800%) rotate(270deg) rotateY(180deg);
     }
     80% {
        opacity:1;
     }
     100% {
        opacity:0;
        transform:translateY(10000%) rotate(1440deg) rotateY(0deg);
     }
  }
`

const Fetti = styled.span.attrs(
  ({
    left,
    animationDelay,
    backgroundColor
  }: FettiProps) => ({
    style: {
      left: `${left}%`,
      animationDelay: `${animationDelay}s`,
      backgroundColor
    }
  })
)<FettiProps>`
  position: absolute;
  z-index: 99;
  width: 6px;
  height: 3px;
  top: ${props => props.logoDown ? 30 : 60}px;
  opacity: 0;
  animation: confetti 3s ease-in infinite;
`

interface FettiProps {
  left: number
  animationDelay: number
  backgroundColor: string
  logoDown: boolean
}


export default Confetti