import React from "react"
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'


const BlockSerializer = ({children, node}): JSX.Element => {
  if(!children) return <></>
  return <Paragraph>{children}</Paragraph>
}

const BlockSerializerNews = ({children}) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: props => (<BlockSerializer {...props} />),
        },
        marks: {
        },
      }}
    />
  )
}



const StyledBlockContent = styled(props => <BlockContent {...props} />)`
    > p {
      margin-bottom: 1em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
`


const Paragraph = styled.p`
  font-size: 1.47rem;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 1.72.rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 2.1rem;
  }
`



export default BlockSerializerNews