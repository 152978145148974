import React from "react"
import styled from "styled-components"
import { useInViewport } from "react-in-viewport"
import { useLazyLoad } from "@utils"
import { palette } from "@theme"
import { LazyMarker } from "./Picture"

const imgixPath = (input: string) =>
  input.replace(
    "cdn.sanity.io/files/zchnowat/production",
    "franklyn-files.imgix.net"
  )

const Video = ({
  autoPlay,
  muted,
  loop,
  hideIfMobile,
  hideIfDesktop,
  playsInline,
  hasFX,
  src,
}: VideoProps): React.ReactElement => {
  if (!src) return <></>

  const ref = React.useRef<HTMLVideoElement>(null)
  const { inViewport } = useInViewport(ref, { threshold: 0.15 })

  const lazyMarkerRef = React.useRef<HTMLDivElement>(null)
  const { loaded: lazyInViewport } = useLazyLoad(lazyMarkerRef)

  return (
    <>
      <LazyMarker ref={lazyMarkerRef} />
      <VideoTag
        {...{
          autoPlay,
          muted,
          loop,
          hideIfMobile,
          hideIfDesktop,
          playsInline,

          hasFX,
          ref,
        }}
        visible={inViewport}
      >
        {lazyInViewport && <source src={imgixPath(src)} />}
      </VideoTag>
    </>
  )
}

interface VideoTagProps {
  rotation?: number
  hideIfDesktop?: boolean
  hideIfMobile?: boolean
  visible: boolean
  hasFX: boolean
}

const VideoTag = styled.video<VideoTagProps>`
  background-color: ${palette.racingGreen};
  width: 100%;
  max-width: 100%;
  @media only screen and (max-width: 743px) {
    ${props => props.hideIfMobile && `display: none;`}
  }
  @media only screen and (min-width: 744px) {
    ${props => props.hideIfDesktop && `display: none;`}
  }
  transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out;
  @media only screen and (min-width: 744px) {
    transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out;
  }
  @media only screen and (max-width: 743px) {
    transform: none !important;
  }
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`

type VideoProps = {
  autoPlay?: boolean
  muted?: boolean
  loop?: boolean
  hideIfMobile?: boolean
  hideIfDesktop?: boolean
  playsInline?: boolean
  src: string
  hasFX: boolean
}

export default Video
