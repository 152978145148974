import React from "react"
import styled from 'styled-components'
import { palette } from '@theme'


const Newsletter = (): React.ReactElement => {
  const [email, setEmail] = React.useState('')
  const [valid, setValid] = React.useState(false)
  React.useEffect(() => setValid(/\S+@\S+\.\S+/.test(email)), [email])
  const submit = () => {
    if(!valid) return
  }
  return (
    <Wrapper
      action="https://quitefranklyn.createsend.com/t/j/s/irildt/" 
      method="post"
    >
      <NewsletterInput 
        type="email" 
        name="cm-irildt-irildt" 
        required={true}
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        placeholder="Your Email"
      />
      <SubmitButton type="submit" disabled={!valid} value="submit" {...{ valid }} onClick={() => submit()}/>
    </Wrapper>
  )
}


const Wrapper = styled.form`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${palette.white};
  padding-bottom: 0px;
  margin-top: 1rem;
  @media only screen and (min-width: 744px) {
    margin-top: 2rem;
  }
`

const NewsletterInput = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: 'CalibreRegular', sans-serif;
  font-size: inherit;
  letter-spacing: 0.05em;
  color: ${palette.white};
  ::placeholder {
    color: rgba(255,255,255, 0.25);
  }
  @media only screen and (min-width: 1024px) {
    padding-bottom: 3px;
  }
`

const SubmitButton = styled.input<{ valid: boolean }>`
  appearance: none;
  position: absolute;
  top: 3px;
  right: 0;
  border: 0;
  outline: 0;
  width: 20px;
  height: 20px;
  @media only screen and (min-width: 744px) {
    top: 3px;
    width: 25px;
    height: 25px;
  }
  background: transparent;
  background-image: url('/images/littleArrow.svg');
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -1000px;
  overflow: hidden;
  cursor: pointer;
  transform: rotateZ(45deg);
  transform-origin: center center;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  opacity: ${props => props.valid ? 1 : 0.5};
  &:hover {
    transform: rotateZ(45deg) scale(${props => props.valid ? 1.25 : 1});
  }
`

export default Newsletter