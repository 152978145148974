import React from "react"
import styled from 'styled-components'
import { Underlined } from '@components/atoms'


interface SectionProps {
  title?: string
  noPadding?: boolean
  className?: string
  children: React.ReactNode
}


const Section = ({ title, noPadding, className, children }: SectionProps): React.ReactElement => {
  return (
    <Wrapper {...{className, noPadding}} >
      {title && <TitleWrapper><Underlined>{title}</Underlined></TitleWrapper>}
      {children}
    </Wrapper>
  )
}


const Wrapper = styled.section<Pick<SectionProps, 'noPadding'>>`
  padding: ${props => props.noPadding ? '0 0 90px 0' : '90px 0'};    
  @media only screen and (min-width: 1024px) {
    padding: ${props => props.noPadding ? '0 0 120px 0' : '120px 0'};    
  }
`


const TitleWrapper = styled.div`
  position: relative;
  padding-bottom: 2em;
  @media only screen and (min-width: 744px) {
    padding-bottom: 60px;
  }
  @media only screen and (max-width: 743px) {
    font-size: 2.1rem;    
  }
`


export default Section