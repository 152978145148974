import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { Link } from "@components/atoms"
import { useWindowSize, useDisplayMode } from "@utils"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import LogoImage from "@assets/images/franklyn-rgb.svg"

interface LogoProps {
  hideMobile: boolean
}

const Logo = ({ hideMobile }: LogoProps): React.ReactElement => {
  const { partyMode } = useDisplayMode()
  const { width } = useWindowSize()
  const { pathname } = useLocation()

  const scrollToTopMaybe = (e: MouseEvent) => {
    if (pathname === "/") {
      e.preventDefault()
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 })
    }
  }

  const [introY, setIntroY] = React.useState<number>()
  const [logoDown, setLogoDown] = React.useState<boolean>()
  const [scale, setScale] = React.useState(1)
  const [logoX, setLogoX] = React.useState(180)
  const [logoY, setLogoY] = React.useState(36)

  React.useEffect(() => {
    setTimeout(() => {
      const logo_marker = document.getElementById("logo_marker")
      setLogoDown(pathname === "/")
      if (logo_marker) {
        setIntroY(logo_marker.getBoundingClientRect().y + window.scrollY)
      }
    }, 750)
  }, [pathname, setLogoDown, width])

  useScrollPosition(
    ({ currPos }) => {
      if (!introY) return
      const distanceFromNavToTop = window.innerWidth < 744 ? 30 : 45
      setLogoDown(
        pathname === "/" &&
          (introY <= 0 || currPos.y <= introY - logoY - distanceFromNavToTop)
      )
    }, // effect callback
    [pathname, setLogoDown, introY, logoY], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10 // performance debounce
  )

  React.useEffect(() => {
    const getFiveColumns = (windowX: number): number => {
      let margin: number = 0,
        gap: number = 0
      switch (true) {
        case width < 1024:
          margin = 30
          gap = 30
          break
        case width < 1200:
          margin = 45
          gap = 30
          break
        default:
          margin = 60
          gap = 30
      }
      const column = (windowX - margin * 2 - gap * 11) / 12
      return column * 5 + gap * 4
    }
    const maxX = getFiveColumns(width)
    setLogoX(maxX > 180 ? maxX : 180)
    setScale(width < 744 ? 2 : 180 / maxX)
    setLogoY(maxX * 0.209)
  }, [width])

  const visible = logoDown !== undefined

  return (
    <Wrapper {...{ logoDown, introY, logoX, logoY, visible, hideMobile }}>
      <SvgWrapper {...{ partyMode, logoDown, scale }}>
        <StyledLink to="/" onClick={(e: MouseEvent) => scrollToTopMaybe(e)}>
          Home
        </StyledLink>
        <LogoImage />
      </SvgWrapper>
    </Wrapper>
  )
}

interface WrapperProps {
  visible: boolean
  logoDown?: boolean
  introY?: number
  logoX: number
  logoY: number
  hideMobile: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: ${props => (props.logoDown ? "absolute" : "fixed")};
  z-index: ${props => (props.hideMobile ? 11000 : 11002)};
  left: 20px;
  overflow: visible;
  width: 118px;
  height: ${props => (props.logoDown ? "158px" : "28px")};
  top: ${props => (props.logoDown ? `0px` : `16px`)};
  max-width: 240px;
  visibility: ${props => (props.visible ? "visible" : "hidden")};

  @media only screen and (min-width: 744px) {
    max-width: none;
    top: ${props => (props.logoDown ? `0px` : `39px`)};
    left: 30px;
    width: ${props => props.logoX}px;
    height: ${props => (props.logoDown ? props.introY ?? 0 : 43)}px;
  }

  @media only screen and (min-width: 1024px) {
    left: 45px;
  }

  @media only screen and (min-width: 1200px) {
    left: 60px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-indent: -9999px;
`

interface SvgWrapperProps {
  scale: number
  logoDown?: boolean
  partyMode: boolean
}

const SvgWrapper = styled.div<SvgWrapperProps>`
  position: absolute;
  display: block;
  ${props => (props.logoDown ? "bottom: 0px;" : "top: 0px;")}
  width: 100%;
  height: auto;

  transition: transform 0.2s ease-in;
  transform-origin: top left;
  transform: scale(${props => (props.logoDown ? 1.5 : 1)});
  will-change: transform;

  @media only screen and (min-width: 744px) {
    transform: scale(${props => (props.logoDown ? 1 : props.scale)});
  }
  border-radius: 0 !important;
  width: 100%;

  @keyframes logoPalette {
    0% {
      fill: #1b672a;
    }
    50% {
      fill: #1b672a;
    }
    100% {
      fill: #1b672a;
    }
  }

  @keyframes partyPalette {
    0% {
      fill: rgb(173, 255, 0);
    }
    20% {
      fill: rgb(0, 104, 38);
    }
    40% {
      fill: rgb(173, 255, 0);
    }
    50% {
      fill: rgb(255, 0, 173);
    }
    60% {
      fill: rgb(173, 255, 0);
    }
    70% {
      fill: rgb(255, 173, 0);
    }
    80% {
      fill: rgb(173, 255, 0);
    }
    90% {
      fill: rgb(173, 0, 255);
    }
    100% {
      fill: rgb(173, 255, 0);
    }
  }

  svg {
    path {
      animation: ${props =>
        props.partyMode
          ? `partyPalette 35s infinite ease-in-out`
          : `logoPalette 10s infinite ease-in-out`};
    }
  }

  @media only screen and (min-width: 744px) {
    svg {
      margin-bottom: -1.25vw;
    }
  }

  @keyframes mobilePartytime {
    0% {
      filter: drop-shadow(-3px 2px 0px rgb(44, 44, 44));
    }
    10% {
      filter: drop-shadow(2px -3px 0px rgb(44, 44, 44));
    }
    20% {
      filter: drop-shadow(-4px 2px 0px rgb(44, 44, 44));
    }
    30% {
      filter: drop-shadow(2px -3px 0px rgb(44, 44, 44));
    }
    40% {
      filter: drop-shadow(-4px 2px 0px rgb(44, 44, 44));
    }
    50% {
      filter: drop-shadow(2px 3px 0px rgb(44, 44, 44));
    }
    60% {
      filter: drop-shadow(-3px 2px 0px rgb(44, 44, 44));
    }
    70% {
      filter: drop-shadow(2px 3px 0px rgb(44, 44, 44));
    }
    80% {
      filter: drop-shadow(-4px -2px 0px rgb(44, 44, 44));
    }
    90% {
      filter: drop-shadow(2px -3px 0px rgb(44, 44, 44));
    }
    100% {
      filter: drop-shadow(-3px 2px 0px rgb(44, 44, 44));
    }
  }
  ${props =>
    props.partyMode && `animation: mobilePartytime 5s infinite ease-in-out;`}

  @keyframes partytime {
    0% {
      filter: drop-shadow(-6px 4px 0px rgb(44, 44, 44));
    }
    10% {
      filter: drop-shadow(4px -6px 0px rgb(44, 44, 44));
    }
    20% {
      filter: drop-shadow(-9px 4px 0px rgb(44, 44, 44));
    }
    30% {
      filter: drop-shadow(4px -6px 0px rgb(44, 44, 44));
    }
    40% {
      filter: drop-shadow(-8px 4px 0px rgb(44, 44, 44));
    }
    50% {
      filter: drop-shadow(4px 5px 0px rgb(44, 44, 44));
    }
    60% {
      filter: drop-shadow(-6px 4px 0px rgb(44, 44, 44));
    }
    70% {
      filter: drop-shadow(4px 7px 0px rgb(44, 44, 44));
    }
    80% {
      filter: drop-shadow(-9px -4px 0px rgb(44, 44, 44));
    }
    90% {
      filter: drop-shadow(4px -6px 0px rgb(44, 44, 44));
    }
    100% {
      filter: drop-shadow(-6px 4px 0px rgb(44, 44, 44));
    }
  }
  @media only screen and (min-width: 744px) {
    ${props =>
      props.partyMode && `animation: partytime 5s infinite ease-in-out;`}
  }
`

export default Logo
