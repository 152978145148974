import React from "react"
import styled from "styled-components"
import { useInViewport } from "react-in-viewport"
import { useLazyLoad } from "@utils"

// TODO import { imgixPath } from '@utils'
const imgixPath = (input: string) =>
  input.replace(
    "cdn.sanity.io/images/zchnowat/production",
    "franklyn.imgix.net"
  )

const Picture = ({
  objectFit = "contain",
  className = "",
  src,
  mobileSrc,
  prettyFilename,
  dimensions,
  alt,
  columns,
  hasFX,
  children,
  ...props
}: PictureProps): React.ReactElement => {
  if (!src) return <></>
  const ref = React.useRef<HTMLImageElement>(null)
  const { inViewport } = useInViewport(ref, { threshold: 0.15 })

  const lazyMarkerRef = React.useRef<HTMLDivElement>(null)
  const { loaded: lazyInViewport } = useLazyLoad(lazyMarkerRef)

  // Set CDN src.
  const mobileCdnSrc = !!mobileSrc ? imgixPath(mobileSrc) : undefined
  const cdnSrc = imgixPath(src)

  // approximate width-of-layout cut by column width
  const desktopCut = (w: number) =>
    !columns ? w : Math.floor(w * (columns / 12))

  const preferredFallbackFormat = src.substring(src.length - 3)

  return (
    <>
      <LazyMarker ref={lazyMarkerRef} />
      <picture {...dimensions}>
        {!!children && children}
        {!children && lazyInViewport && (
          <>
            {mobileCdnSrc && (
              <source
                type="image/webp"
                media="(max-width: 743px)"
                srcSet={`${mobileCdnSrc}?fm=webp&w=840`}
              />
            )}
            {!mobileSrc && (
              <source
                type="image/webp"
                media="(max-width: 743px)"
                srcSet={`${cdnSrc}?fm=webp&w=840`}
              />
            )}
            <source
              type="image/webp"
              media="(min-width: 1280px)"
              srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(
                1200
              )}, ${cdnSrc}?fm=webp&w=${desktopCut(2400)} 2x`}
            />
            <source
              type="image/webp"
              media="(min-width: 1024px)"
              srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(
                1024
              )}, ${cdnSrc}?fm=webp&w=${desktopCut(2048)} 2x`}
            />
            <source
              type="image/webp"
              media="(min-width: 744px)"
              srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(
                744
              )},  ${cdnSrc}?fm=webp&w=${desktopCut(1488)} 2x`}
            />
          </>
        )}
        {lazyInViewport && (
          <StyledImage
            src={`${cdnSrc}?fm=${preferredFallbackFormat}`}
            alt={alt}
            {...{ objectFit, ref, hasFX }}
            visible={inViewport}
            className={`loadable ${className}`}
            {...props}
            download={prettyFilename}
          />
        )}
      </picture>
    </>
  )
}

// Styling

export const LazyMarker = styled.div`
  position: absolute;
  top: -15vh;
  display: block;
  width: 1px;
  height: 1px;
  pointer-events: none;
`

interface StyledImageProps {
  visible: boolean
  objectFit: "contain" | "cover"
  aspectRatio: number
  hasFX: boolean
}

const StyledImage = styled.img<StyledImageProps>`
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  object-fit: ${props => props.objectFit};
  transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out;
  @media only screen and (min-width: 744px) {
    transition: opacity 0.5s ease-in-out, border-radius 0.25s ease-in-out;
  }
  @media only screen and (max-width: 743px) {
    transform: none !important;
  }
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`

// Typing

type PictureProps = {
  src: string
  mobileSrc?: string
  prettyFilename?: string
  objectFit?: "contain" | "cover"
  dimensions: {
    width: number
    height: number
    aspectRatio: number
  }
  className?: string
  alt?: string
  columns?: number
  mobileColumns?: number
  hasFX: boolean
  children?: React.ReactNode
}

export default Picture
