import React from "react"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import { palette } from "@theme"

const BlockSerializer = ({ children, node }): JSX.Element => {
  if (!children) return <></>
  switch (node.style) {
    case "h1":
      return <H1>{children}</H1>
    case "h2":
      return <H2>{children}</H2>
    case "h3":
      return <H3>{children}</H3>
    case "c1":
      return <Caption>{children}</Caption>
    case "c2":
      return <CaptionSmall>{children}</CaptionSmall>
    case "smallParagraph":
      return <Psmall>{children}</Psmall>
    case "smallParagraphCenter":
      return <Psmall center>{children}</Psmall>
    case "quoteHuge":
      return <Blockquote className="huge">{children}</Blockquote>
    case "quoteLarge":
      return <Blockquote className="large">{children}</Blockquote>
    case "quoteMedium":
      return <Blockquote className="medium">{children}</Blockquote>
    case "quoteSmall":
      return <Blockquote className="small">{children}</Blockquote>
    case "cite":
      return <Citation>{children}</Citation>
    default:
      return <Paragraph>{children}</Paragraph>
  }
}

const BlockRenderer = ({ children }) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: props => <BlockSerializer {...props} />,
        },
        marks: {
          inlineSmall: props => <InlineSmall {...props} />,
          grey: props => <GreyText {...props} />,
          green: props => <GreenText {...props} />,
          link: ({ children, mark }) => {
            return (
              <a {...mark} target="_blank">
                {children}
              </a>
            )
          },
        },
      }}
    />
  )
}

const StyledBlockContent = styled(props => <BlockContent {...props} />)`
  > p {
    margin-bottom: 1em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ul {
    margin-left: 25px;
  }
  li {
    list-style-type: disc;
  }
`

const H1 = styled.h1`
  font-family: "HCo Sentinel SSm", serif;
  font-weight: 300;
  font-size: 4.8rem;
  letter-spacing: 0;
  line-height: 1.15em;
  color: #1b672a;
  @media only screen and (min-width: 744px) {
    font-size: 6.6rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 7.2rem;
  }
`

// color: #1b672a;
const H2 = styled.h2`
  font-family: "HCo Sentinel SSm", serif;
  font-weight: 300;
  font-size: 3rem;
  letter-spacing: 0;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 4.2rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 4.2rem;
  }
  margin-bottom: 1em;
  &:last-child,
  &:last-of-type {
    margin-bottom: 0;
  }
`

const H3 = styled.h3`
  font-family: "HCo Sentinel SSm", serif;
  font-weight: 300;
  font-size: 2.1rem;
  letter-spacing: 0;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 3rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 3.6rem;
  }
`

const Paragraph = styled.p`
  font-size: 2.1rem;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 2.4rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 3rem;
  }
`

const Psmall = styled.p<{ center?: boolean }>`
  text-align: ${props => (props.center ? "center" : "left")};
  font-size: 1.8rem;
  line-height: 1.7em;
  margin-bottom: 0.25em !important;
  @media only screen and (min-width: 744px) {
    line-height: 1.6em;
    font-size: 2.1rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 2.4rem;
  }
`

const Caption = styled.p`
  font-family: "AkkuratMono", monospace;
  font-size: 1rem;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 1.25rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 1.4rem;
  }
`

const CaptionSmall = styled.p`
  font-family: "AkkuratMono", monospace;
  font-size: 0.9rem;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 1.1rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 1.25rem;
  }
`

const Blockquote = styled.blockquote`
  position: relative;
  font-family: "HCo Sentinel SSm", serif;
  font-weight: 300;
  line-height: 1.2em;
  letter-spacing: 0;

  &.huge {
    font-size: 3.6rem;
  }
  &.large {
    font-size: 2.4rem;
  }
  font-size: 1.8rem;
  &.small {
    font-size: 1.2rem;
  }
  @media only screen and (min-width: 744px) {
    &.huge {
      font-size: 4.8rem;
    }
    &.large {
      font-size: 3.6rem;
    }
    font-size: 2.4rem;
    &.small {
      font-size: 2rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    &.huge {
      font-size: 6.8rem;
    }
    &.large {
      font-size: 4.8rem;
    }
    font-size: 3rem;
    &.small {
      font-size: 2rem;
    }
  }

  &:before {
    content: "“";
    position: absolute;
    display: block;
    left: -0.45em;
  }
  &:after {
    content: "”";
  }
`

const GreyText = styled.span`
  color: ${palette.silver};
`

const GreenText = styled.span`
  color: ${palette.lime};
`

const InlineSmall = styled.span`
  font-family: "AkkuratMono", monospace;
  font-size: 0.9rem;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 1.1rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 1.25rem;
  }
`

const Citation = styled.cite`
  position: relative;
  display: block;
  font-size: 1.8rem;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 2.1rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 2.4rem;
  }
  > span {
    padding-left: 25px;
  }
  padding-top: 3em;
  &:before {
    content: " ";
    position: absolute;
    left: 0px;
    top: 2em;
    width: 70px;
    height: 2px;
    background-color: ${palette.silver};
  }
`

export default BlockRenderer
