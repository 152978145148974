import React from "react"
import styled from "styled-components"

const Grid = ({
  children,
  className,
  noPadding,
  wideMobileRows,
}: GridProps): React.ReactElement => (
  <Wrapper {...{ className, noPadding, wideMobileRows }}>{children}</Wrapper>
)

{
  /* const Placeholder = styled.div`
  position: relative;
  grid-column-end: span 1;
  outline: 1px dashed white;
  &:after {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 0, 0, 0.1);
    content: ' ';
  }
` */
}

const Wrapper = styled.div<Pick<GridProps, "noPadding" | "wideMobileRows">>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: ${props => (props.wideMobileRows ? 90 : 25)}px;
  padding: ${props => (props.wideMobileRows ? 60 : props.noPadding ? 0 : 25)}px
    0;

  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 60px;
    padding: ${props => (props.noPadding ? 0 : 90)}px 0;
  }

  @media only screen and (min-width: 1024px) {
    padding: ${props => (props.noPadding ? 0 : 120)}px 0;
  }
`

type GridProps = {
  noPadding?: boolean
  wideMobileRows?: boolean
  children: React.ReactNode
  className?: string
}

export default Grid
