import React from "react"
import styled from 'styled-components'
import { DisplayMode, useDisplayMode } from '@utils'
import { palette } from '@theme'


interface MarginProps {
  children?: React.ReactNode
  className?: string
  noGutter?: boolean
  black?: boolean
  id?: string
}


const Margin = ({
  children,
  className,
  noGutter = false,
  black,
  id = undefined,
}: MarginProps): React.ReactElement => {
  const { displayMode } = useDisplayMode()
  return (
    <Wrapper className={className} {...{ noGutter, black, displayMode, id }} >{children}</Wrapper>
  )
}


const Wrapper = styled.div<Pick<MarginProps, 'noGutter' | 'black'> & { displayMode: DisplayMode }>`
  padding-left: ${props => props.noGutter ? 0 : 20}px;
  padding-right: ${props => props.noGutter ? 0 : 20}px;
  margin: 0 auto;

  @media only screen and (min-width: 744px) {
    padding-left: ${props => props.noGutter ? 0 : 30}px;
    padding-right: ${props => props.noGutter ? 0 : 30}px;
  }

  @media only screen and (min-width: 1024px) {
    padding-left: ${props => props.noGutter ? 0 : 45}px;
    padding-right: ${props => props.noGutter ? 0 : 45}px;
  }

  @media only screen and (min-width: 1200px) {
    padding-left: ${props => props.noGutter ? 0 : 60}px;
    padding-right: ${props => props.noGutter ? 0 : 60}px;
  }

  ${props => props.black && `
    background-color: ${props.displayMode === 'dark' ? palette.black : 'rgb(235, 235, 235)'};
    transition: background-color 0.15s ease-in-out;
  `}
`


export default Margin
