import React from "react"
import styled from 'styled-components'
import { useDisplayMode, DisplayMode } from '@utils'
import { palette } from '@theme'


interface UnderlinedProps {
  className?: string
  children: React.ReactNode
}


const Underlined = ({ className, children }: UnderlinedProps): React.ReactElement => {
  const { displayMode } = useDisplayMode()
  return (
    <Wrapper {...{displayMode, className}} ><span>{children}</span></Wrapper>
  )
}

const Wrapper = styled.div<{ displayMode: DisplayMode }>`
  span {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      bottom: -7px;
      width: 100%;
      height: 2px;
      background-color: ${props => props.displayMode === 'dark' ? palette.white : palette.mineShaft};
    }      
  }
`

export default Underlined