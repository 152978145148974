import React from "react"
import styled from "styled-components"
import { Grid, InputSwitch, Margin } from "@components/atoms"
import { Newsletter } from "@components/organisms"
import { palette } from "@theme"
import LogoImage from "@assets/images/franklyn-white.svg"
import {
  GridHelperSwitch,
  useDisplayMode,
  useDisplayModeDispatch,
} from "@utils"

const Footer = (): React.ReactElement => {
  const displayModeDispatch = useDisplayModeDispatch()
  const { displayMode, appMode, partyMode } = useDisplayMode()
  const now = new Date()

  const togglePartyMode = () => {
    displayModeDispatch({ type: "togglePartyMode" })
  }

  return (
    <Wrapper>
      <Grid noPadding>
        <ContactInfo>
          <DataItem>
            <Label>New business?</Label>
            <EmailLink href="mailto:hello@franklyn.co">
              hello@franklyn.co
            </EmailLink>
          </DataItem>

          <DataItem>
            <Label>Proudly Based in Brooklyn (and Beyond)</Label>
            <span>
              <a href="tel://+17183040737">(718) 304-0737</a>
            </span>
          </DataItem>

          <DataItem>
            <Label>Jobs &amp; Internships</Label>
            <a href="mailto:jobs@franklyn.co">jobs@franklyn.co</a>
          </DataItem>

          <DataItem>
            <Label>Sporadic Newsletter Signup</Label>
            <Newsletter />
          </DataItem>

          <Social>
            <SocialLink
              href="https://instagram.com/franklyn.design"
              target="_blank"
            >
              Instagram
            </SocialLink>
            <SocialLink
              href="https://twitter.com/franklyn_design"
              target="_blank"
            >
              Twitter
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/franklyn"
              target="_blank"
            >
              LinkedIn
            </SocialLink>
          </Social>
        </ContactInfo>

        <SwitchWrapper>
          {/* <StyledInputSwitch
            name="themeSwitch"
            onChange={() => displayModeDispatch({ type: "toggleDisplayMode" })}
            checked={displayMode === "light"}
            labelColor={palette.lime}
            backgroundColor={palette.black}
            foregroundColor={palette.lime}
            mobileOnly
          >
            Day Mode
          </StyledInputSwitch> */}
          {/*
          <StyledInputSwitch 
            name="appModeSwitch" 
            onChange={() => displayModeDispatch({type: 'toggleAppMode'})} 
            checked={appMode} 
            labelColor={palette.lime} 
            backgroundColor={palette.black}
            foregroundColor={palette.lime}
            mobileOnly
          >App Mode</StyledInputSwitch>
*/}
          <StyledInputSwitch
            name="partyModeSwitch"
            onChange={() => togglePartyMode()}
            checked={partyMode}
            labelColor={palette.lime}
            backgroundColor={palette.black}
            foregroundColor={palette.lime}
            mobileOnly
          >
            Party Mode
          </StyledInputSwitch>
          {process.env.GATSBY_SANITY_WATCH_MODE && (
            <GridHelperSwitch
              labelColor={palette.lime}
              backgroundColor={palette.black}
              foregroundColor={palette.lime}
            />
          )}
        </SwitchWrapper>
      </Grid>

      <LogoGrid noPadding>
        <StyledLogoImage />
        <Copyright>&copy; {now.getFullYear()} Franklyn</Copyright>
        <GridColumnWidth id="gridColumnWidth" />
      </LogoGrid>
    </Wrapper>
  )
}

const Wrapper = styled(props => <Margin {...props} />)`
  position: relative;
  z-index: 2000;
  background-color: ${palette.funGreen};
  background-color: color(display-p3 0 0.4 0.185);
  @media only screen and (max-width: 743px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 65px);
  }
  padding-top: 25px;
  padding-bottom: 25px;
  @media only screen and (min-width: 744px) {
    z-index: 12000;
    padding-top: 45px;
    padding-bottom: 45px;
  }
  @media only screen and (min-width: 744px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const ContactInfo = styled.footer`
  position: relative;
  grid-column: span 6;
  @media only screen and (min-width: 744px) and (max-width: 1023px) {
    grid-column: span 8;
  }
  @media only screen and (min-width: 1024px) {
    grid-column: span 7;
  }
  @media only screen and (min-width: 1200px) {
    grid-column: span 6;
  }
`

const SwitchWrapper = styled.div`
  position: absolute;
  right: 0;
  @media only screen and (min-width: 744px) {
    position: relative;
    right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-column-end: span 3;
    grid-column-start: 10;
  }
`

const StyledInputSwitch = styled(props => <InputSwitch {...props} />)<{
  mobileOnly?: boolean
}>`
  @media only screen and (max-width: 743px) {
    display: none;
  }
`

const DataItem = styled.div`
  margin-bottom: 40px;
  a {
    display: inline-block;
    color: ${palette.white};
  }
  span {
    display: block;
  }
  @media only screen and (min-width: 1024px) {
    margin-bottom: 80px;
  }
`

const Label = styled.div`
  font-family: "AkkuratMono", monospace;
  font-size: 1rem;
  line-height: 1.4em;
  margin-bottom: 5px;
  @media only screen and (min-width: 744px) {
    font-size: 1.25rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 1.4rem;
  }
  color: ${palette.lime};
`

const EmailLink = styled.a`
  @media only screen and (min-width: 744px) {
    font-family: "HCo Sentinel SSm", serif;
    font-weight: 300;
    font-size: 3.2rem;
    line-height: 1.4em;
    letter-spacing: 0;
    color: ${palette.white};
  }
  @media only screen and (min-width: 1024px) {
    font-size: 3.6rem;
  }
`

const Social = styled.div`
  width: 110px;
  @media only screen and (min-width: 744px) {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 130px;
  }
  @media only screen and (min-width: 744px) {
    width: 150px;
  }
`

const SocialLink = styled.a`
  position: relative;
  display: block;
  color: ${palette.white};
  @media only screen and (min-width: 375px) {
    &:after {
      content: " ";
      background-image: url("/images/littleArrow.svg");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0px;
      top: 3px;
      width: 20px;
      height: 20px;
    }
  }
  @keyframes spin1 {
    0% {
      transform: rotate3d(1, -1, 0, 0deg);
    }
    100% {
      transform: rotate3d(1, -1, 0, 180deg);
    }
  }
  @media only screen and (min-width: 744px) {
    &:after {
      top: 5px;
      width: 25px;
      height: 25px;
      transition: transform 0.5s ease-in-out;
    }
    &:hover:after {
      animation: spin1 0.5s linear infinite;
    }
  }
`

const StyledLogoImage = styled(props => <LogoImage {...props} />)`
  grid-column: span 6;
  @media only screen and (min-width: 744px) {
    grid-column: span 12;
  }
  width: 100%;
  height: auto;
  path {
    fill: ${palette.white};
  }
`

const Copyright = styled.div`
  grid-column: span 5;
  font-family: "AkkuratMono", monospace;
  font-size: 1rem;
  line-height: 0;
  margin-top: 25px;
  @media only screen and (min-width: 744px) {
    margin-top: 0;
    font-size: 1.25rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 1.4rem;
  }
  color: ${palette.white};
`

// "What the heck is this?"
// Glad you asked. The grid is dynamic (to screen width), and various function need to figure out what a single column width is.
// ex. a grid in a scroller that is wider than the window width; "12 fr" won't cut it.
// We're doing a big dumb "getElementById" to measure this.
const GridColumnWidth = styled.div`
  grid-column: 1 !important;
`

const LogoGrid = styled(props => <Grid {...props} />)`
  grid-row-gap: 0 !important;
  @media only screen and (min-width: 743px) {
    margin-top: 40px;
  }
`

export default Footer
