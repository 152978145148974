import React from "react"
import styled from "styled-components"
import { useDisplayMode, DisplayMode } from "@utils"
import { TransitionState } from "gatsby-plugin-transition-link"

type TransitionStatus = "entering" | "entered" | "exiting" | "exited"

interface TransitionMaskProps {
  displayMode?: DisplayMode
  transitionStatus: TransitionStatus
}

const TransitionMask = ({
  transitionStatus,
}: TransitionMaskProps): React.ReactElement => {
  const { displayMode } = useDisplayMode()
  return (
    <Mask
      {...{ displayMode, transitionStatus }}
      className={transitionStatus === "entered" ? "" : "transitioning"}
    />
  )
}

const Mask = styled.div<TransitionMaskProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  transition: opacity 0.5s 0.1s ease-in-out, visibility 0.01s 0.51s;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(
    ${props =>
      props.displayMode === "light" ? "255, 255, 255" : "12, 37, 27"},
    1
  );
  &.transitioning {
    pointer-effects: none;
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in-out, visibility 0.01s 0.01s;
    visibility: visible;
  }
`

const WrappedMask = () => (
  <TransitionState>
    {({ transitionStatus }: TransitionMaskProps) => (
      <TransitionMask {...{ transitionStatus }} />
    )}
  </TransitionState>
)

export default WrappedMask
