import React from "react"
import styled from 'styled-components'
import { useDisplayMode } from '@utils'


const FlyingEmoji = () => {
  const { partyMode } = useDisplayMode()
  if(!partyMode) return <></>
  
  const emojis = [
    '🤪','🐙','🥰','😻','🤟','☘️','🍑','🍕','💚','💕','💘','🎉','🔪','🗽','🏀',
    '👻','🐨','👾','💃🏼','🐠','🍄','🍒','🍩','🛵','💿','🎈','🍟','🏓','⏰','💯',
    '🤪','🐙','🥰','😻','🤟','☘️','🍑','🍕','💚','💕','💘','🎉','🔪','🗽','🏀',
    '👻','🐨','👾','💃🏼','🐠','🍄','🍒','🍩','🛵','💿','🎈','🍟','🏓','⏰','💯',
  ]
    
  return (
    <Wrapper>
      {emojis.map((e,j) => (
        <Emoji key={`emoji_${j}`} >{e}</Emoji>
      ))}
    </Wrapper>
  )
}


const Wrapper = styled.div`
  position: fixed;
  z-index: 99998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  @keyframes fly {
    0% {
      visibility: hidden;
      transform:translate(0, -5vh);
    }
    5% {
      visibility: visible;
      transform:translate(0, -5vh);
    }
    95% {
      transform:translate(100vw, 105vh);
    }
    100% {
      visibility: hidden;
      transform:translate(100vw, 105vh);
    }
  }
`



interface EmojiProps {
  children: React.ReactNode,
}


const Emoji = ({ children }: EmojiProps) => {
  const [speed, setSpeed] = React.useState<number>(undefined)
  const [delay, setDelay] = React.useState<number>(undefined)
  const [left, setLeft] = React.useState<number>(undefined)
  React.useEffect(() => {
    setSpeed(Math.random() * 2 + 2) // 2 seconds
    setLeft(Math.random() * 150 - 60) // 2 seconds
    setDelay(Math.random() * 2)
  }, [setSpeed, setLeft])
  return <EmojiWrapper {...{ speed, left, delay }} >{children}</EmojiWrapper>
}


const EmojiWrapper = styled.div<{
  speed: number,
  left: number,
  delay: number,
}>`
  transform:translate(0, -5vh);
  position: absolute;
  left: ${props => props.left}%;
  animation: fly ${props => props.speed}s ${props => props.delay}s linear infinite;
`


export default FlyingEmoji