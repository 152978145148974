import React from "react"
import styled from 'styled-components'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Margin, Grid } from '@components/atoms'
import ArrowUp from '@assets/images/arrowUp.svg'


const ScrollUpArrow = (): React.ReactElement => {
  const [scrollPosition, setScrollPosition] = React.useState({x: 0, y: 0})
  useScrollPosition(
    ({ currPos }) => setScrollPosition(currPos), // callback
    [setScrollPosition], // dependencies
    undefined, // element to track (none, just watching window scroll)
    true, // use window instead of body.getBoundingRect
    500 // performance debounce
  )
  const scrollUp = () => window.scrollTo({behavior: 'smooth', left: 0, top: 0})

  return (
    <StyledMargin active={(scrollPosition.y > 500)}>
      <Grid noPadding>
        <SvgWrapper onClick={() => scrollUp()} >
          <ArrowUp />
        </SvgWrapper>
      </Grid>
    </StyledMargin>
  )
}



const StyledMargin = styled(props => <Margin {...props} />)<{active: boolean}>`
  position: fixed;
  bottom: 25px;
  @media only screen and (min-width: 744px) {
    bottom: 50px;
  }
  left: 0;
  width: 100%;
  z-index: 11001;
  transition: opacity 0.20s ease-in-out, transform 0.25s ease-in-out;
  opacity: ${props => props.active ? 1 : 0};
  transform: ${props => props.active ? 'translateY(0px)' : 'translateY(50px)'};
  pointer-events: none;
`


const SvgWrapper = styled.div`
  cursor: pointer;
  grid-column-start: 6;
  grid-column-end: span 1;
  margin: auto;
  @media only screen and (min-width: 744px) {
    grid-column-start: 12;
  }
  pointer-events: all;
`


export default ScrollUpArrow