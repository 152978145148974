import React from "react"
import styled from "styled-components"
import {
  Link,
  Logo,
  MenuButton,
  InputSwitch,
  Confetti,
} from "@components/atoms"
import { palette } from "@theme"
import {
  useDisplayMode,
  useDisplayModeDispatch,
  DisplayMode,
  DisplayModeActionType,
} from "@utils"
import { useLocation } from "@reach/router"

const Header = (): React.ReactElement => {
  const [navVisible, setNavVisible] = React.useState(false)
  const displayModeDispatch = useDisplayModeDispatch()
  const { displayMode, appMode, partyMode } = useDisplayMode()
  const { pathname } = useLocation()
  const dispatchAndCloseNav = (type: DisplayModeActionType) => {
    displayModeDispatch({ type: type })
    setNavVisible(false)
  }

  return (
    <>
      <Confetti logoDown={true} scale={1} />
      <Logo hideMobile={navVisible} />
      <StyledMargin>
        <Navigation {...{ navVisible }}>
          <ul>
            <NavItem
              {...{ displayMode }}
              onClick={() => setNavVisible(false)}
              active={pathname === "/work"}
            >
              <Link to="/work">Work</Link>
            </NavItem>
            <NavItem
              {...{ displayMode }}
              onClick={() => setNavVisible(false)}
              active={pathname === "/lately"}
            >
              <Link to="/lately">Lately</Link>
            </NavItem>
            <NavItem
              {...{ displayMode }}
              onClick={() => setNavVisible(false)}
              active={pathname === "/studio"}
            >
              <Link to="/studio">Studio</Link>
            </NavItem>
          </ul>
          {/* <StyledInputSwitch
            name="themeSwitch"
            onChange={() => dispatchAndCloseNav("toggleDisplayMode")}
            checked={displayMode === "light"}
            labelColor={palette.mineShaft}
            backgroundColor={palette.black}
            foregroundColor={palette.lime}
            inputFirst
          >
            Day Mode
          </StyledInputSwitch> */}
          <StyledInputSwitch
            name="appModeSwitch"
            onChange={() => dispatchAndCloseNav("toggleAppMode")}
            checked={appMode}
            labelColor={palette.mineShaft}
            backgroundColor={palette.black}
            foregroundColor={palette.lime}
          >
            App Mode
          </StyledInputSwitch>
          <StyledInputSwitch
            name="partyModeSwitch"
            onChange={() => dispatchAndCloseNav("togglePartyMode")}
            checked={partyMode}
            labelColor={palette.mineShaft}
            backgroundColor={palette.black}
            foregroundColor={palette.lime}
          >
            Party Mode
          </StyledInputSwitch>
        </Navigation>
        <MenuButtonWrapper onClick={() => setNavVisible(!navVisible)}>
          <MenuButton active={navVisible} />
        </MenuButtonWrapper>
      </StyledMargin>
    </>
  )
}

const StyledMargin = styled.div`
  position: fixed;
  z-index: 11001;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  @media only screen and (min-width: 744px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (min-width: 1024px) {
    padding-left: 45px;
    padding-right: 45px;
  }

  @media only screen and (min-width: 1200px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`

const StyledInputSwitch = styled(props => <InputSwitch {...props} />)`
  @media only screen and (max-width: 744px) {
    display: inline-flex;
    margin: 1em auto 0 0;
  }
  @media only screen and (min-width: 744px) {
    display: none;
  }
`

const NavItem = styled.li<{ active: boolean; displayMode: DisplayMode }>`
  display: block;

  @media only screen and (max-width: 743px) {
    a {
      color: ${palette.mineShaft} !important;
    }
    font-size: 8.6rem;
    line-height: 1.1em;
    font-family: "HCo Sentinel SSm", serif;
    font-weight: 300;
    letter-spacing: 0px;
  }
  @media only screen and (min-width: 744px) {
    a {
      color: ${props =>
        props.active && props.displayMode === "dark"
          ? palette.lime
          : !props.active && props.displayMode === "dark"
          ? palette.white
          : props.active && props.displayMode !== "dark"
          ? palette.funGreen
          : "rgb(159, 159, 159)"} !important;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: ${props =>
          props.displayMode === "dark"
            ? palette.lime
            : palette.funGreen} !important;
      }
    }
    padding-right: 30px;
    font-family: "CalibreRegular", sans-serif;
    @media only screen and (min-width: 744px) {
      font-size: 2.1rem;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 2.4rem;
    }
  }
`

const Navigation = styled.nav<{ navVisible?: boolean }>`
  @media only screen and (max-width: 743px) {
    position: fixed;
    z-index: 30000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    padding: 20px;
    visibility: hidden;
    touch-events: none;
    opacity: 0;
    transform: scale(1.05);
    transition: visibility 0.01s 0.35s, opacity 0.3s 0s ease-in-out,
      transform 0.3s 0s ease-in-out;
    will-change: opacity, transform;
    ${props =>
      props.navVisible &&
      `
      visibility: visible;
      touch-events: all;
      transform: scale(1);
      opacity: 1;
      transition: 
        visibility 0.01s, 
        opacity 0.3s 0.1s ease-in-out, 
        transform 0.3s 0.1s ease-in-out;
    `}
    background-color: ${palette.lime};
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    ul {
      margin-top: -120px;
      pointer-events: all;
      margin-bottom: 3em;
    }
  }
  @media only screen and (min-width: 744px) {
    width: 100%;
    margin-top: 45px;
    text-align: left;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;

    ul {
      pointer-events: all;
      grid-column-start: 7;
      grid-column-end: span 6;
      display: flex;
      flex-direction: row;
    }
  }
  @media only screen and (min-width: 1024px) {
    right: 45px;
  }
  @media only screen and (min-width: 1200px) {
    right: 60px;
  }
`

const MenuButtonWrapper = styled.div`
  @media only screen and (min-width: 744px) {
    display: none;
  }
  position: absolute;
  z-index: 30001;
  top: 0px;
  right: 0px;
  padding: 20px 20px 20px 20px;
`

export default Header
