import React from "react"
import styled from 'styled-components'
import TransitionLink from 'gatsby-plugin-transition-link'
import { palette } from '@theme'


const MoreBug = ({children, to}: {children?: React.ReactNode, to: string}): React.ReactElement => {
  const corners = [
    { x: 0, y: 0 },
    { x: 100, y: 0 },
    { x: 0, y: 100 },
    { x: 100, y: 100 },
  ]
  const coordinates = corners[Math.floor(Math.random()*4)];
  const speed = Math.random() * 20
  return (
    <TransitionLink {...{ to }} className="moreBug" {...coordinates} >
      <Circle {...{speed}} >{children || 'Click<br />for more'}</Circle>
    </TransitionLink>
  )
}



const Wrapper = styled.div<{ x: number, y: number }>`
  display: block;
  position: absolute;
  left: calc(${props => props.x}% - 35px);
  top: ${props => props.y === 100 ? `calc(100% - 120px)` : '0%'};
  @media only screen and (min-width: 744px) {
    transform: scale(.85);
    transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.25s ease-in-out;    
  }
`

const Circle = styled.div<{speed: number}>`
  position: relative;
  width: 70px;
  height: 70px;
  padding: 5px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;
  background: ${palette.lime};
  color: ${palette.black};
  font-family: 'AkkuratMono', monospace;
  text-align: center;

  transform-origin: center center;
  animation: rotating ${props => props.speed}s linear infinite;
  
  @media only screen and (min-width: 744px) {
    width: 100px;
    height: 100px;
  }
  
  font-size: 0.9rem;
  line-height: 1.4em;
  @media only screen and (min-width: 744px) {
    font-size: 1.1rem;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 1.25rem;
  }
`


export default MoreBug