// source: https://github.com/streamich/react-use
// not adding the entire package because, jeez, that's a lot of package
// but might revisit this decision

import { useState, useCallback, useEffect } from "react"

/**
 * read and write url hash, response to url hash change
 */
const useHash = () => {
  const [hash, setHash] = useState(() => (typeof window !== 'undefined') ? window.location.hash : '')

  const onHashChange = useCallback(() => {
    setHash((typeof window !== 'undefined') ? window.location.hash : '')
  }, [])

  useLifecycles(() => {
    if( typeof window === 'undefined' ) return;
    window.addEventListener('hashchange', onHashChange)
  }, () => {
    if( typeof window === 'undefined' ) return;
    window.removeEventListener('hashchange', onHashChange)
  })

  const _setHash = useCallback((newHash: string) => {
    if (newHash !== hash) {
      if( typeof window === 'undefined' ) return;
      window.location.hash = newHash
    }
  }, [hash])

  return [hash, _setHash] as const
}


const useLifecycles = (mount: () => void, unmount?: () => void) => {
  useEffect(() => {
    if (mount) {
      mount();
    }
    return () => {
      if (unmount) {
        unmount();
      }
    };
  }, []);
};

export default useHash;