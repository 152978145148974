import React from "react"

export type DisplayModeActionType =
  | "setLight"
  | "setDark"
  | "toggleDisplayMode"
  | "toggleAppMode"
  | "togglePartyMode"

interface DisplayModeAction {
  type: DisplayModeActionType
  payload?: {}
}

type Dispatch = (action: DisplayModeAction) => void
interface State {
  displayMode: DisplayMode
  appMode: boolean
  partyMode: boolean
}
type DisplayModeProviderProps = {
  children: React.ReactNode
}
type DisplayMode = "light" | "dark"

const initialState: State = {
  displayMode: "light",
  appMode: false,
  partyMode: false,
}
const DisplayModeContext = React.createContext<State | undefined>(initialState)
const DisplayModeDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
)

function displayModeReducer(state: State, action: DisplayModeAction): State {
  switch (action.type) {
    case "setLight": {
      document?.getElementById("body")?.classList.add("light")
      return { ...state, displayMode: "light" }
    }
    case "setDark": {
      // document?.getElementById("body")?.classList.remove("light")
      // return { ...state, displayMode: "dark" }
      return state
    }
    case "toggleDisplayMode": {
      // document?.getElementById("body")?.classList.toggle("light")
      // return {
      //   ...state,
      //   displayMode: state.displayMode === "light" ? "dark" : "light",
      // }
      return state
    }
    case "toggleAppMode": {
      document?.getElementById("body")?.classList.toggle("appModeOn")
      return { ...state, appMode: state.appMode ? false : true }
    }
    case "togglePartyMode": {
      document?.getElementById("body")?.classList.toggle("partyModeOn")
      return { ...state, partyMode: state.partyMode ? false : true }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function DisplayModeProvider({ children }: DisplayModeProviderProps) {
  const [state, dispatch] = React.useReducer(displayModeReducer, initialState)
  return (
    <DisplayModeContext.Provider value={state}>
      <DisplayModeDispatchContext.Provider value={dispatch}>
        {children}
      </DisplayModeDispatchContext.Provider>
    </DisplayModeContext.Provider>
  )
}

function useDisplayMode() {
  const context = React.useContext(DisplayModeContext)
  if (context === undefined) {
    throw new Error("useDisplayMode must be used within a DisplayModeProvider")
  }
  return context
}

function useDisplayModeDispatch() {
  const context = React.useContext(DisplayModeDispatchContext)
  if (context === undefined) {
    throw new Error(
      "useDisplayModeDispatch must be used within a DisplayModeProvider"
    )
  }
  return context
}

export {
  DisplayModeProvider,
  useDisplayMode,
  useDisplayModeDispatch,
  DisplayMode,
}
