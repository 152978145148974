import React from "react"
import styled from 'styled-components'


interface PageWrapperProps {
  hasHero?: boolean
  children: React.ReactNode
  className?: string
}


const PageWrapper = ({hasHero, className, children}: PageWrapperProps) => (
  <Wrapper {...{ hasHero, className }} >{children}</Wrapper>
)


const Wrapper = styled.main<{ hasHero?: boolean }>`
  min-height: 100vh;
  ${props => !props.hasHero && `
    padding-top: 100px;
    @media only screen and (min-width: 744px) {
      padding-top: 140px;
    }
    @media only screen and (min-width: 1024px) {
      padding-top: 190px;
    }
    @media only screen and (min-width: 1200px) {
      padding-top: 260px;
    }
  `}
`

export default PageWrapper